const consts = () => { return {
    default_color : "#20492d",
    default_color_light : "#37c970",
    drawer_width : 280,
    map_center: [50.002324, 36.226589],
    compass_alpha: "0.3",
    gps_accuracy_alpha: "0.2",
    animation_timeout: 2000,
    //Ограничения для карты
    //верх(больше)/лево/низ(меньше)/право
    mapBounds: [[50.0110, 36.2085],[39.9938, 36.2428]],
    //В каких пределах находится слой-картаплан
    imgLayerBounds: [[50.004850, 36.22055],[50.00057, 36.23015]],
    defaultMode: "map", //osm
    appBar: "btn" // или "full"
    }
}

export default consts()