import logo from "../icons/logo2.png";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";
import React from "react";
import consts from "../consts";

const AppButtons = (props) => {
    return(
        <div className="fixed-buttons">
            <div style={{position:"fixed",left:"13px",bottom:"15px",zIndex:"500"}}>
                <img src={logo} className="App-logo" alt="logo" style={{width:"100px",height:"auto",borderRadius:"5px"}}/>
            </div>

            <IconButton onClick={props.centerGeoPosition} sx={ ()=> {
                    const style = {left:"10px",top:"20px"}
                    props.deviceonCenter.current && props.geoCoords.available ?
                        style.backgroundColor = "#77afdb!important" : style.backgroundColor = consts.default_color
                    return style
                }
            }>
                <GpsFixedIcon sx={ () =>{
                    const style = {}
                    props.geoCoords.available ? style.fill = "white" : style.fill="gray"
                    return style
                }}
                />
            </IconButton>

            <IconButton onClick={props.handleSearchDrawer}  sx={{left:"10px",top:"80px"}}>
                <SearchIcon
                    style={{fill:"white"}}
                />
            </IconButton>

            <IconButton onClick={props.handleSettingsDrawer}   sx={{left:"10px",top:"140px"}}>
                <MenuIcon
                    style={{fill:"white"}}
                />
            </IconButton>
        </div>
    )
}

export default AppButtons