import { useEffect, useRef} from "react";

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import DialogContent from '@mui/material/DialogContent';
import {Button} from "@mui/material";


const ShowInfo = function(props){

    const descriptionElementRef = useRef(null);
    useEffect(() => {
        if (props.show) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [props.show]);

    return (
        <div>
            <Dialog
                open={props.show}
                onClose={props.handleShow}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogContent dividers={true} sx={{padding:"0", border:"0"}}>
                    <Card sx={{ maxWidth: 300}} ref={descriptionElementRef}>
                        <IconButton
                            aria-label="close"
                            onClick={props.handleShow}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                backgroundColor: "rgb(72 72 72 / 12%)"
                            }}
                        >
                            <CloseIcon sx={{fill: "white"}}/>
                        </IconButton>
                        <CardMedia
                        component="img"
                        image={`${props.data.img}`}
                        alt={`"${props.data.name}"`}
                    />
                        <CardHeader title={props.data.name} sx={{fontSize:"1.4rem",paddingBottom:"0"}}></CardHeader>
                        <CardContent sx={{paddingTop:"8px"}}>
                            <div
                                dangerouslySetInnerHTML={{__html : props.data.description}}
                                style={{fontWeight:"400",fontSize:"0.9rem",lineHeight:"1.43",letterSpacing:"0.008em",color:"#424242"}}
                            />
                            <Button variant="outlined" size="small" sx={{margin:"15px 0 5px 0"}} onClick={() => props.setRoute(props.data.geodata[0], props.data.geodata[1])}>Прокласти маршрут</Button>
                        </CardContent>
                        {props.data.exp_img && <CardMedia
                            component="img"
                            image={`${props.data.exp_img}`}
                            alt={`${props.data.name} - експозиція`}
                        />}
                    </Card>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default ShowInfo